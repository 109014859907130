import { Injectable } from "@angular/core";
import { IID_OL_ESPLETATO_OK } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDolEspletatoOkQuery } from "./espletatoOk.query";
import { IIDolEspletatoOkStore } from "./espletatoOk.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolEspletatoOkService extends CrudService<IID_OL_ESPLETATO_OK, IIDolEspletatoOkStore, IIDolEspletatoOkQuery> {
  constructor(
    protected store: IIDolEspletatoOkStore,
    protected query: IIDolEspletatoOkQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_ESPLETATO_OK,
      store,
      query,
      actionService,
      updateStrategy
    );
  }
}
