import { Injectable } from "@angular/core";
import { IID_OL_ACQUISITO_KO } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDolAquisitiKoQuery } from "./aquisitiKo.query";
import { IIDolAquisitiKoStore } from "./aquisitiKo.store";

@Injectable({
  providedIn: 'root'
})
export class IIDolAquisitiKoService extends CrudService<IID_OL_ACQUISITO_KO, IIDolAquisitiKoStore, IIDolAquisitiKoQuery> {
  constructor(
    protected store: IIDolAquisitiKoStore,
    protected query: IIDolAquisitiKoQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_ACQUISITO_KO,
      store,
      query,
      actionService,
      updateStrategy
    );
  }
}
