import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { QueryEntity } from "@datorama/akita";
import { IssueTypeInterfaceState, IssueTypeInterfaceStore } from "./issueTypeInterface.store";

@Injectable({
  providedIn: 'root'
})
export class IssueTypeInterfaceQuery extends QueryEntity<IssueTypeInterfaceState> {
  all$ = this.selectAll();

  getByIssueTypeId(id: ID) {
    return this.getAll({
      filterBy: entity => entity.issueTypeId === id
    })
  }

  getTypeByIssueTypeId(id: ID, interfaceName: InterfaceNameValue) {
    return this.getAll({
      filterBy: entity => entity.issueTypeId === id && entity.interfaceName === interfaceName
    })[0];
  }

  selectByIssueTypeId$(id: ID) {
    return this.selectAll({
      filterBy: entity => entity.issueTypeId === id
    })
  }

  constructor(protected store: IssueTypeInterfaceStore) {
    super(store);
  }
}
