import { IBaseInterfaceData } from "@common/interfaces/base";
import { ID } from "@common/interfaces/id";
import { EntityState, EntityStore, QueryEntity } from "@datorama/akita";

export class AbstractInterfaceDataQuery<T extends IBaseInterfaceData> extends QueryEntity<EntityState<T, ID>> {
  all$ = this.selectAll();

  getByIssueId(id: ID) {
    return this.getAll({
      filterBy: [
        entity => entity.issueId === id,
        entity => !entity.deletedAt,
      ]
    });
  }

  selectByIssueId$(id: ID) {
    return this.selectAll({
      filterBy: [
        entity => entity.issueId === id,
        entity => !entity.deletedAt        
      ]
    });
  }

  constructor(protected store: EntityStore<EntityState<T, ID>>) {
    super(store);
  }
}
