import { Injectable } from "@angular/core";
import { FormlySelectOption } from "@common/interfaces/formly";
import { InterfaceName, InterfaceNameValue, getInterfaceNameKeyFromValue } from "@common/interfaces/issueTypeInterface";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'any'
})
export class SettingsService {
  constructor(
    private translate: TranslateService) { }

  interfacesToOptions(items: InterfaceNameValue[], showInterfaceValue: boolean = true): FormlySelectOption[] {
    return items.map((item) => {
      const interfaceName = Object.entries(InterfaceName).find(([key, value]) => value === item);
      let name = `${this.translate.instant(`INTERFACES._TITLES.${getInterfaceNameKeyFromValue(interfaceName[1])?.toUpperCase()}`)}`;
      if (showInterfaceValue) {
        name += ` (${(interfaceName[0].toString().replace(/_/g, " "))})`;
      }
      return {
        id: interfaceName[1].toString(),
        name
      }
    });
  }
  interfacesToOptionsWithNameAsId(items: InterfaceNameValue[], showInterfaceValue: boolean = true): FormlySelectOption[] {
    return items.map((item) => {
      const interfaceName = Object.entries(InterfaceName).find(([key, value]) => value === item);
      const id = getInterfaceNameKeyFromValue(interfaceName[1]);
      let name = `${this.translate.instant(`INTERFACES._TITLES.${id.toUpperCase()}`)}`;
      if (showInterfaceValue) {
        name += ` (${(interfaceName[0].toString().replace(/_/g, " "))})`;
      }
      return {
        id,
        name
      }
    });
  }
}
