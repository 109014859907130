import { Injectable } from '@angular/core';
import { InterfaceNameValue } from '@common/interfaces/issueTypeInterface';
import { DEFAULT_UI_INTERFACE, InterfaceSettings } from '@common/interfaces/workflow';
import { ID, Store, StoreConfig } from '@datorama/akita';
import { Position } from '@ep-om/project/service/geolocation.service';

export interface UiState {
  prjId: ID;
  openedInterface: InterfaceNameValue[];
  expandedNavbar: boolean;
  interface: InterfaceSettings;
  isSmallScreen: boolean;
  lastPosition: Position;
}

const initialState: UiState = {
  prjId: null,
  openedInterface: [],
  expandedNavbar: true,
  interface: DEFAULT_UI_INTERFACE,
  isSmallScreen: false,
  lastPosition: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui' })
export class UiStore extends Store<UiState> {
  constructor() {
    super({});
  }
}
