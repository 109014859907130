import { Injectable } from '@angular/core';
import { IID_ISSUE_LINK } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDIssueLinkStore } from './iidIssueLink.store';
import { IIDIssueLinkQuery } from './iidIssueLink.query';
import { WsACKCallback } from '@common/api/ack';
import { IIssue } from '@common/interfaces/issue';
@Injectable({
  providedIn: 'root'
})
export class IIDIssueLinkService extends CrudService<IID_ISSUE_LINK, IIDIssueLinkStore, IIDIssueLinkQuery> {
  constructor(
    protected store: IIDIssueLinkStore,
    public query: IIDIssueLinkQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_ISSUE_LINK,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

  createIssueAndLink(issue: Partial<IIssue>, parentIssueId: string, interfaceIssueLinkName: string, callback?: WsACKCallback<void>) {
    this.actionService.sendMessage(Topics.IID_ISSUE_LINK, 'createIssueAndLink', { issue, parentIssueId, interfaceIssueLinkName }, callback);
  }

  createLink(linkToCreate: Partial<IID_ISSUE_LINK>, callback?: WsACKCallback<void>) {
    this.actionService.sendMessage(Topics.IID_ISSUE_LINK, 'create', linkToCreate, callback);
  }

  deleteLink(linkToDeleteId: string, callback?: WsACKCallback<void>) {
    this.actionService.sendMessage(Topics.IID_ISSUE_LINK, 'delete', { id: linkToDeleteId }, callback);
  }
}
