import { Injectable } from '@angular/core';
import { AppFeatures } from '@common/features';
import { InterfaceNameValue } from '@common/interfaces/issueTypeInterface';
import { InterfaceSettings, UI_INTERFACE_EVERYTHING_FALSE, UI_SETTINGS_SYSADMIN_MIN, UiSettings } from '@common/interfaces/workflow';
import { AppService } from '@ep-om/app.service';
import { AuthService } from '@ep-om/project/auth/auth.service';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { ProjectService } from '../project/project.service';
import { WorkflowService } from '../workflow/workflow.service';
import { UiQuery } from './ui.query';
import { UiStore } from './ui.store';
import { IUser } from '@common/interfaces/user';
import { SystemRole } from '@common/interfaces/permissions';
import { ProjectScopeService } from '../projectScope/projectScope.service';
import { IProject } from '@common/interfaces/project';
import { IssueTypeWorkflowQuery } from '../issueTypeWorkflow/issueTypeWorkflow.query';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  readonly NULLIFY_ROUTES_FOR = ['openEditUserModal', 'openCreateIssueModal'];
  public langReady$ = new BehaviorSubject(false);
  uiSettingsForProjectAndUserRole$: Observable<{ project: IProject, uiSettings: InterfaceSettings }[]>;
  projectsWhereICanCreateTask$: Observable<IProject[]>;

  constructor(
    private store: UiStore,
    public query: UiQuery,
    private authService: AuthService,
    private projectService: ProjectService,
    private appService: AppService,
    private workflowService: WorkflowService,
    private issueTypeWorkflowQuery: IssueTypeWorkflowQuery,
    private projectScopeService: ProjectScopeService,
  ) {
    console.log('uiservice constructor');
    this.authService.authQuery.loggedIn$
      .pipe(
        distinctUntilChanged(),
        tap(loggedin => console.log('file: ui.service.ts:32 : loggedin:', loggedin)),
        filter((loggedIn) => loggedIn === true),
        tap(loggedin => console.log('file: ui.service.ts:32 : loggedin:', loggedin)),
        switchMap((_) => this.projectService.query.active$.pipe(filter((project) => !!project))),
        switchMap((project) =>
          combineLatest([
            this.workflowService.query.selectWorkflowSettingsByWorkflowId$(project.workflowId),
            this.authService.authQuery.projectRole$.pipe(
              map((pR) => pR ? pR[project.id] : undefined)
            ),
            this.authService.authQuery.user$
          ])
        ),

        map(([workflowSettinigs, projectRoles, user]) =>
          this.selectCurrentSettings(workflowSettinigs?.uiSettings, projectRoles, user)
        ),
        catchError((err, caught) => caught),
      )
      .subscribe({
        next: (settings) => {
          console.log('update ui interface settings');
          this.store.update({ interface: settings });
        },
        error: (err) => {
          console.error(err);
        }
      });

    this.uiSettingsForProjectAndUserRole$ = combineLatest([
      this.authService.authQuery.user$,
      this.projectService.query.selectAll(),
      this.authService.authQuery.projectRole$,
      this.workflowService.query.selectAll()])
      .pipe(
        filter(([user, projects, projectRoles, workflows]) => !!user && !!projectRoles),
        map(([user, projects, projectRoles, workflows]) => {
          let prInfo: { project: IProject, uiSettings: InterfaceSettings }[] = [];
          for (let p of projects) {
            const wfSettingsForProject = workflows.find(c => c.id === p.workflowId)?.settings;
            const projectRoleNames = projectRoles[p.id] ?? undefined;
            const settings = this.selectCurrentSettings(wfSettingsForProject?.uiSettings, projectRoleNames, user);
            prInfo.push({ project: p, uiSettings: settings });
          }
          return prInfo;
        })
      );

    this.projectsWhereICanCreateTask$ = combineLatest([
      this.uiSettingsForProjectAndUserRole$,
      this.issueTypeWorkflowQuery.selectValidForCreation$()])
      .pipe(
        map(([ps, types]) => {
          return ps.filter(
            c => c.project.settings.manualTasksCreation
              && c.uiSettings.projectMenu.features.createButton
              && types.map(t => t.workflowId).includes(c.project.workflowId)
          ).map(c => c.project);
        })
      );

    // fromEvent(window, 'load').pipe(delayWhen(() => this.query.isSmallScreen$.pipe(take(1)))),
    merge(
      this.authService.authQuery.loggedIn$.pipe(filter((loggedIn) => !!loggedIn)),
      fromEvent(window, 'resize')
    )
      .pipe(
        map(() => window.innerWidth <= 700),
        filter((eventSmallScreen) => eventSmallScreen != this.query.getValue().isSmallScreen)
      )
      .subscribe((eventSmallScreen) => {
        console.log('UI: before update', this.query.getValue().isSmallScreen);
        this.store.update((state) => ({
          ...state,
          isSmallScreen: eventSmallScreen,
          expandedNavbar: !eventSmallScreen
        }));
        console.log('UI: after update', this.query.getValue().isSmallScreen);
      });
  }

  getSettingsByProjIdAndUser(projectId: string, user?: IUser): InterfaceSettings {
    if (!user) {
      user = this.authService.authQuery.getLoggedUser();
    };
    const project = this.projectService.query.getEntity(projectId);
    const uiSettings: UiSettings = this.workflowService.query.getEntity(project.workflowId).settings?.uiSettings;
    const userRolesInProject = this.projectScopeService.query.getEntity(projectId).users.find(us => us.id === user.id).roles;
    const res = this.selectCurrentSettings(uiSettings, userRolesInProject, user);
    return res;
  }

  selectCurrentSettings(uiSettings: UiSettings, myRolesInThisProject: string[], user: IUser) {
    console.log('myRolesInThisProject:', myRolesInThisProject)
    let interfaceSettings = this.getSettings(uiSettings, myRolesInThisProject, user);

    interfaceSettings = this.fixSettingsBasedOnTenantFeatures(interfaceSettings);
    console.log('interfaceSettings:', interfaceSettings);

    return interfaceSettings;
  }

  getSettings(uiSettings: UiSettings, myRolesInThisProject: string[], user: IUser): InterfaceSettings {
    if ((!myRolesInThisProject || !uiSettings || Object.keys(uiSettings).length === 0)) {
      if (user.globalRoleId === SystemRole.SysAdmin) {
        return UI_SETTINGS_SYSADMIN_MIN;
      } else {
        return UI_INTERFACE_EVERYTHING_FALSE;
      }
    }

    const myRolesSettings = myRolesInThisProject.map((role) => uiSettings[role] || null);
    if (myRolesSettings.length === 0) {
      return UI_INTERFACE_EVERYTHING_FALSE;
    }

    let settings: InterfaceSettings;
    if (myRolesSettings.length === 1) {
      settings = myRolesSettings[0];
    } else {
      //settings = myRolesSettings.reduce(this.deepMergeSum);
      settings = myRolesSettings.reduce(
        (acc, obj) => (acc = this.deepMergeSum(acc, obj))
      );
    }
    return {
      ...settings,
      // if user is sysAdmin, settings in ProjectMenu should always be visibile
      ...(user.globalRoleId === SystemRole.SysAdmin && {
        projectMenu: {
          ...settings.projectMenu,
          visible: true,
          features: {
            ...settings.projectMenu.features,
            settings: true,
            issue: true
          }
        }
      })
    };
  }

  deepMergeSum(obj1: any, obj2: any): any {
    if (!obj1) return obj2;
    if (!obj2) return obj1;
    return Object.keys(obj1).reduce((acc: any, key) => {
      if (typeof obj2[key] === 'object') {
        acc[key] = this.deepMergeSum(obj1[key], obj2[key]);
      } else if (obj2.hasOwnProperty(key) && typeof obj2[key] === 'string') {
        acc[key] = obj1[key];
      } else if (obj2.hasOwnProperty(key) && typeof obj2[key] === 'boolean') {
        acc[key] = obj1[key] || obj2[key];
      }
      return acc;
    }, {});
  }

  updateOpenedInterface(active: boolean, interfaceName: InterfaceNameValue) {
    this.store.update((state) => {
      let openedInterface = [...(state.openedInterface || [])];
      if (active) {
        openedInterface.push(interfaceName);
      } else {
        openedInterface = openedInterface.filter((iName) => iName !== interfaceName);
      }
      return {
        ...state,
        openedInterface
      };
    });
  }

  langReady(boolean: boolean) {
    this.langReady$.next(boolean);
  }

  setCurrentProject(id) {
    this.store.update({ prjId: id });
  }

  toggleNavbar() {
    this.store.update((state) => ({
      ...state,
      expandedNavbar: !state.expandedNavbar
    }));
  }

  hideNavbar() {
    this.store.update({ expandedNavbar: false });
  }

  showNavbar() {
    this.store.update({ expandedNavbar: true });
  }

  // TODO: i tipi Position frontnd e backend non corrispondono
  updateLastPosition(position: any) {
    this.store.update({ lastPosition: position });
  }

  fixSettingsBasedOnTenantFeatures(interfaceSettings: InterfaceSettings) {
    if (this.appService.FEATURES[AppFeatures.MAP] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        issueMenu: {
          defaultView: interfaceSettings.issueMenu.defaultView === 'map' ? 'board' : interfaceSettings.issueMenu.defaultView,
          items: {
            ...interfaceSettings.issueMenu.items,
            map: false
          }
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.CALENDAR] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        issueMenu: {
          defaultView:
            interfaceSettings.issueMenu.defaultView === 'calendar' ? 'board' : interfaceSettings.issueMenu.defaultView,
          items: {
            ...interfaceSettings.issueMenu.items,
            calendar: false
          }
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.MILESTONE] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        issueTable: {
          ...interfaceSettings.issueTable,
          milestone: false
        },
        filterSettings: {
          ...interfaceSettings.filterSettings,
          milestone: false
        },
        projectMenu: {
          ...interfaceSettings.projectMenu,
          features: {
            ...interfaceSettings.projectMenu.features,
            milestone: false
          }
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.EXTERNAL_TASKS] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        issueModal: {
          ...interfaceSettings.issueModal,
          external_tasks: false
        },
        projectMenu: {
          ...interfaceSettings.projectMenu,
          features: {
            ...interfaceSettings.projectMenu.features,
            external_tasks: false
          }
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.TAGS] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        addIssueModal: {
          ...interfaceSettings.addIssueModal,
          tags: false
        },
        issueModal: {
          ...interfaceSettings.issueModal,
          tags: false
        },
        issueTable: {
          ...interfaceSettings.issueTable,
          tags: false
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.HISTORY] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        issueModal: {
          ...interfaceSettings.issueModal,
          history: false
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.ATTACHMENTS] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        issueModal: {
          ...interfaceSettings.issueModal,
          attachments: false
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.ARCHIVE] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        issueModal: {
          ...interfaceSettings.issueModal,
          archive: false
        },
        projectMenu: {
          ...interfaceSettings.projectMenu,
          features: {
            ...interfaceSettings.projectMenu.features,
            archive: false
          }
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.OLO_REQ_LOG] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        issueModal: {
          ...interfaceSettings.issueModal,
          olo_req_log: false
        },
        projectMenu: {
          ...interfaceSettings.projectMenu,
          features: {
            ...interfaceSettings.projectMenu.features,
            olo_req_log: false
          }
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.EXPORT] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        projectMenu: {
          ...interfaceSettings.projectMenu,
          features: {
            ...interfaceSettings.projectMenu.features,
            export: false
          }
        }
      };
    }

    if (this.appService.FEATURES[AppFeatures.DOCUMENTATION] === 'DISABLED') {
      interfaceSettings = {
        ...interfaceSettings,
        projectMenu: {
          ...interfaceSettings.projectMenu,
          features: {
            ...interfaceSettings.projectMenu.features,
            documentation: false
          }
        }
      };
    }

    return interfaceSettings;
  }
}
