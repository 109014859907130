import { IAppointment } from "@common/interfaces/appointment";
import { IBaseInterfaceData } from "@common/interfaces/base";
import { ID } from "@common/interfaces/id";
import { IIssue } from "@common/interfaces/issue";
import { ISSUE_RELATIONS_INTERFACES } from "@common/interfaces/IssueRelations";
import { getInterfaceNameIterable, InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { SystemRole, SystemUser } from "@common/interfaces/permissions";
import { IProjectScope } from "@common/interfaces/projectScope";
import { IUserWithRoles } from "@common/interfaces/user";
import { Automations, CreationRules, Flow, OperativityInterfaceRule, OperativityInterfaceSettings, StateRule, SuspensionFlow, WorkFlowSettings } from "@common/interfaces/workflow";
import { ObjectHelpers as OH } from '@common/utils/object.helpers';

export type InterfaceDataContext = Record<InterfaceNameValue, IBaseInterfaceData[]>;
export type TargetUser = 'Reporter' | 'Assignee';
export type PerformerUser = TargetUser | 'Admin' | 'Manager' | 'Creator';

export type IIDPermission = 'editable' | 'readonly' | 'hidden' | 'not_visible';
export type InterfaceDataPermissions = Record<InterfaceNameValue, IIDPermission>

// TODO da verificare ma dovrebbe far rifermineto a InterfaceName backend/src/common/interfaces/issueTypeInterface.ts
export const defaultInterfacePermissions: InterfaceDataPermissions = {
  0: 'editable',
  1: 'editable',
  2: 'editable',
  3: 'editable',
  4: 'editable',
  5: 'editable',
  6: 'editable',
  7: 'editable',
  8: 'editable',
  9: 'editable',
  10: 'editable',
  11: 'editable',
  12: 'editable',
  13: 'editable',
  14: 'hidden',
  15: 'editable',
  16: 'editable',
  17: 'readonly',
  18: 'not_visible',
  19: 'editable',
  20: 'editable',
  21: 'editable',
  22: 'hidden',
  23: 'editable',
  24: 'hidden',
  25: 'hidden',
  26: 'editable',
  27: 'editable',
  28: 'editable',
  29: 'readonly',
  30: 'readonly',
  31: 'hidden',
  32: 'hidden',
  33: 'readonly',
  'gr_resources': 'readonly',
  'gr_tasks': 'readonly',
  'gr_resources_map': 'readonly',
  'activation_request': 'editable',
  'assurance_ticket': 'editable',
  'generic_ok': 'hidden',
  'network_items': 'editable',
  'fault_management': 'editable',
  'generic_appointment': 'hidden',
  'olo_request_of': 'readonly',
  'olo_resource_pni': 'readonly',
  'espletamento_positivo_of': 'readonly',
  'issue_link': 'editable',
  'issue_linked': 'readonly'
}

export interface IIssueContext {
  issue: IIssue,
  interfaceData: InterfaceDataContext,
}

export interface DeltaInterface {
  interfaceName: InterfaceNameValue,
  delta: Partial<IBaseInterfaceData>
}

export interface DeltaParameters {
  automation: Automations,
  issue: IIssue,
  newStateId: ID,
  projectScope: IProjectScope,
  appointments?: IAppointment[],
  usersWorkload?: Map<ID, UserWorkload>
}

export interface UserWorkload {
  assignee: number,
  reporter: number
}

export type CanChangeStateDTO = {
  settings: WorkFlowSettings,
  issueContext: IIssueContext,
  newStateId: ID,
  user: IUserWithRoles,
};

export class WorkflowDog {

  static canViewState(settings: WorkFlowSettings, user: IUserWithRoles, stateId: ID) {
    if (!settings || !settings.hiddenStatesByRole || settings.hiddenStatesByRole.length === 0) {
      return true;
    }
    const canView = settings.hiddenStatesByRole.reduce((canView, rule) => {
      if (canView === false) {
        return false;
      }
      if (rule.states.includes(stateId)) {
        return !user.roles.some(role => rule.roles.includes(role))
      }
      return true;
    }, true);

    return canView;

  }

  static canViewInterface(settings: WorkFlowSettings, user: IUserWithRoles, stateId: ID, taskType: string, interfaceName: string) {
    if (!settings || !settings.hiddenInterfacesByRole || settings.hiddenInterfacesByRole.length === 0) {
      return true;
    }
    const canView = settings.hiddenInterfacesByRole.filter(c => c.interface === interfaceName).reduce((canView, rule) => {
      if (canView === false) {
        return false;
      }
      let settingsForTaskType: OperativityInterfaceSettings[] = [];
      let settingForState: OperativityInterfaceSettings[] = [];
      // prima guardo se c'è un setting specifico per task type che prevede lo stato in questione (o un qualsiasi stato)
      settingsForTaskType = rule.settings.filter(c => c.taskTypes.includes(taskType) && (c.states.includes(stateId) || c.states.includes('*')));
      // se non c'è, guardo se c'è il task type generico che prevede lo stato in questione (o un qualsiasi stato)
      if (!settingsForTaskType || settingsForTaskType.length === 0) {
        settingsForTaskType = rule.settings.filter(c => c.taskTypes.includes('*') && (c.states.includes(stateId) || c.states.includes('*')));
      }
      // se arrivo qui, vuol dire che c'è un setting in cui c'è il task type che mi interessa e lo stato (generico o specifico)
      if (!!settingsForTaskType && settingsForTaskType.length > 0) {
        // prima guardo se c'è qualcosa per lo stato specifico
        settingForState = settingsForTaskType.filter(c => c.states.includes(stateId));
        if (!settingForState || settingForState.length === 0) {
          settingForState = settingsForTaskType.filter(c => c.states.includes('*'));
        }
      }
      // Devo guardare la regola: se è specificata quella che indica i ruoli ammessi, ha priorità su quella che indica i ruoli cui è nascosta
      if (!!settingForState) {
        let ruleRestrictedVisibility = settingForState.find(c => c.rule === OperativityInterfaceRule.VISIBLE_FOR_ROLES);
        if (!!ruleRestrictedVisibility) {
          return user.roles.some(role => ruleRestrictedVisibility.roles.includes(role));// l'utente vede l'interfaccia se ha almeno un ruolo richiesto
        }

        let ruleHiddenVisibility = settingForState.find(c => c.rule === OperativityInterfaceRule.HIDDEN_FOR_ROLES);
        if (!!ruleHiddenVisibility) {
          //return !user.roles.some(role => ruleHiddenVisibility.roles.includes(role)); // l'utente non vede l'interfaccia se ha almeno un ruolo proibito
          return !user.roles.every(role => ruleHiddenVisibility.roles.includes(role)); // l'utente non vede l'interfaccia se ha solo ruoli proibiti
        }
      }
      return true;
    }, true);

    return canView;
  }

  static canCreateTask(settings: WorkFlowSettings, visibleInterfaces: { [key: string]: boolean }, user: IUserWithRoles, issue: IIssueContext) {
    if (!issue.interfaceData || Object.keys(issue.interfaceData).length === 0) {
      return true;
    }
    if (!settings || !settings.creationRules) {
      return true;
    }
    if (user.roles.includes('Service') || user.roles.includes('Viewer')) {
      return false;
    }
    return this.validateCreationRules(settings.creationRules, visibleInterfaces, issue);
  }

  private static validateCreationRules(rules: CreationRules[], visibleInterfaces: { [key: string]: boolean }, issue: IIssueContext) {
    if (!rules) {
      return true;
    }
    return rules.every(rule => {
      // Se l'interfaccia non è visibile in creazione per quel task type, passo oltre
      if (!!visibleInterfaces && !visibleInterfaces[rule.id]) {
        return true;
      }
      const interfaceDataArray = issue.interfaceData[rule.id];
      // Visto che le regole di creazione del task sono per workflow, ma un workflow può avere più tipi di task
      // non è detta che il task che sto creando abbia quell'interfaccia.
      // Patch: se non trovo l'interfaccia, restituisco true...
      if (!interfaceDataArray) {
        return true;
      }
      switch (rule.value) {
        case 'isValid': {
          return interfaceDataArray.every(elem => elem.isValid);
        }
        case 'isNotEmpty': {
          return interfaceDataArray.every(thing => !OH.hasOnlyEmptyValues(thing));
        }
        case 'isEmpty': {
          return interfaceDataArray.every(thing => OH.hasOnlyEmptyValues(thing));
        }
        case 'atLeastOne': {
          return interfaceDataArray.length > 0;
        }
      }
    })
  }

  static canChangeState(dto: CanChangeStateDTO): boolean {
    const { settings, issueContext, newStateId, user } = dto;
    const cantChange = 'Can\'t change state, ';
    if (!!issueContext.issue.suspension) {
      console.error(cantChange, 'suspension is active')
      return false;
    }

    if (!Object.values(SystemUser).includes(user.id as SystemUser)) {
      const userIdentity = this.getUserIdentities(user, issueContext.issue);
      if (user.roles.includes('Service') || user.roles.includes('Viewer') || userIdentity.length === 0) {
        console.error(cantChange, 'user has role Service or Viewer or identity was not found');
        return false;
      }
    }

    if (!settings || !settings.flow || settings.flow.length === 0) {
      return false;
    }

    console.log('workflow dog - there are settings to check');

    const issue = issueContext.issue;

    if (issue.stateId === newStateId) {
      return true;
    }

    const flows = settings.flow.filter(flow => {
      const result = (flow.from.includes(issue.stateId) && flow.to.includes(newStateId)) ||
        (flow.from.includes("*") && flow.to.includes(newStateId)) ||
        (flow.to.includes("*") && flow.from.includes(issue.stateId)) ||
        (flow.from.includes("*") && flow.to.includes("*"));
      return result;
    });

    console.log('workflow dog - found flows:', flows, 'to check');

    if (flows.length === 0) {
      return false;
    }

    const result = flows.every(flow => {
      //const temp = this.validateFlowConditions(flow, issueContext, projectScope)
      const temp1 = this.validateFlowInterfaces(flow, issueContext);
      return /*temp &&*/ temp1;
    });

    /*
    // TODO: Se sono offline: devo controllare se allo stato sono associati trigger che non possono essere eseguiti offline
    let offline = true;
    if (offline) {
      let automations = this.getAutomationForState(settings, newStateId);
      if (automations && automations.triggers && automations.triggers.length > 0) {
        for (let trigger of automations.triggers) {
          let metadata = Reflect.getMetadata(METADATA_KEY_ONLINE_ONLY, CustomTrigger.prototype, trigger.name);
          if (metadata) {            
            console.log("La funzione puà essere eseguita solo online!");
          }
        }
      }
    }
    */

    return result;
  }

  static validateFlowInterfaces(flow: Flow, issueContext: IIssueContext) {
    if (!flow.interfaces) {
      return true;
    }
    return flow.interfaces.every(elem => {
      const interfaceDataArray = issueContext.interfaceData[elem.id];

      // l'interfaccia non esiste su questo issue type
      if (elem.value !== 'atLeastOne' && interfaceDataArray.length === 0) {
        return true;
      }

      switch (elem.value) {
        case 'isEmpty':
          return interfaceDataArray.every(elem => OH.hasOnlyEmptyValues(elem));
        case 'isNotEmpty':
          return interfaceDataArray.every(thing => !OH.hasOnlyEmptyValues(thing));
        case 'isValid':
          return interfaceDataArray.every(elem => elem.isValid);
        case 'atLeastOneIsValid':
          return interfaceDataArray.some(elem => elem.isValid);
        case 'atLeastOne':
          return interfaceDataArray.length > 0;
        default:
          return false;
      }
    })
  }

  static canSuspend(issue: IIssue, settings: WorkFlowSettings) {
    if (!settings) {
      return false;
    }
    if (!settings.suspension.enabled) {
      return false;
    }
    if (!!issue.suspension) {
      return false;
    }
    if (settings.suspension.notAllowedStates.includes(issue.stateId)) {
      return false;
    }
    return true;
  }

  static getStatesFromSuspension(flow: SuspensionFlow[], stateId: ID) {
    console.log('analyzing suspension flow', flow);
    if (!flow) return undefined;

    const specificFlow = flow.find(f => f.from.includes(stateId));
    if (specificFlow) {
      console.log('found specificFlow:', specificFlow);
      return specificFlow.to[0] === '__prev__' ? stateId : specificFlow.to[0];
    }



    const anyFlow = flow.find(f => f.from.includes('*'));
    if (!anyFlow) {
      console.log('found nothing');
      return undefined;
    }
    console.log('found any flow', anyFlow)
    return anyFlow.to[0] === '__prev__' ? stateId : specificFlow.to[0];
  }

  private static getUserIdentities(user: IUserWithRoles, issue: IIssue): PerformerUser[] {
    const result: PerformerUser[] = [];

    //Esempio Admin
    //if(user.role === '#ADMIN#') {
    //  result.push('Admin')
    //}
    if (user.roles.includes('Admin')) {
      result.push('Admin');
    }

    if (user.roles.includes('Manager')) {
      result.push('Manager');
    }

    if (user.id === issue.reporterId) {
      result.push('Reporter');
    }

    if (user.id === issue.assigneeId) {
      result.push('Assignee');
    }

    return result;
  }

  static canPerformUserChange(settings: WorkFlowSettings, issue: IIssue, targetUser: TargetUser, performedByUser: IUserWithRoles) {
    //If empty settings or empty ruleset, permit all changes

    if (!settings || !settings.rules || settings.rules?.length === 0) return true;

    for (const performedBy of this.getUserIdentities(performedByUser, issue)) {
      const neededRule = `${performedBy}CanChange${targetUser}` as StateRule;

      const specificRules = settings.rules.filter(rule => rule.onState.includes(issue.stateId) && rule.stateRules.includes(neededRule));

      const defaultRules = settings.rules.filter(rule => rule.onState.includes('*') && rule.stateRules.includes(neededRule));

      if (specificRules.length > 0)
        return true;

      if (defaultRules.length > 0)
        return true;
    }

    return false;
  }

  static getUsersInRoleReporterForAutomation(automation: Automations, projectScope: IProjectScope, appointments?: IAppointment[]): ID[] {
    let userIds = [];
    if (automation.reporter) {
      userIds = projectScope.users.filter(u => u.roles.includes(automation.reporter))?.map(c => c.id);
    }
    else {
      userIds = projectScope.users?.map(c => c.id);
    }
    if (automation.reporter_preference) {
      const resourcePreference = WorkflowDog.getReporterResourceFromAppointments(appointments, automation.reporter_preference);
      if (resourcePreference.resourceId) {
        userIds.push(resourcePreference.resourceId);
        userIds = [...new Set(userIds)];
      }
    }
    return userIds;
  }

  static getUsersInRoleAssignamentForAutomation(automation: Automations, projectScope: IProjectScope, appointments?: IAppointment[]): ID[] {
    let userIds = [];
    if (automation.assignee) {
      userIds = projectScope.users.filter(u => u.roles.includes(automation.assignee))?.map(c => c.id);
    }
    else {
      userIds = projectScope.users?.map(c => c.id);
    }
    if (automation.assignee_preference) {
      const resourcePreference = WorkflowDog.getAssigneeResourceFromAppointments(appointments, automation.assignee_preference);
      if (resourcePreference.resourceId) {
        userIds.push(resourcePreference.resourceId);
        userIds = [...new Set(userIds)];
      }
    }
    return userIds;
  }

  static getOnlyReadonlyInterface(): InterfaceDataPermissions {
    const effects: Partial<InterfaceDataPermissions> = {};

    for (const elem of getInterfaceNameIterable()) {
      effects[elem] = 'readonly';
    }

    return effects as InterfaceDataPermissions;
  }

  static getInterfaceDataPermissions(settings: WorkFlowSettings, issue: IIssue, user: IUserWithRoles): InterfaceDataPermissions {
    const baseEffects: Partial<InterfaceDataPermissions> = {}

    for (const elem of getInterfaceNameIterable()) {
      baseEffects[elem] = defaultInterfacePermissions[elem];
    }

    const result = baseEffects as InterfaceDataPermissions

    // se non ci sono settings permetto di vedere ed editare tutte le interfacce
    if (!settings) {
      return result;
    }

    if (Array.isArray(issue.hiddenInterfaces)) {
      for (const elem of issue.hiddenInterfaces) {
        baseEffects[elem] = 'hidden';
      }
    }

    if (Array.isArray(issue.visibleInterfaces)) {
      for (const elem of issue.visibleInterfaces) {
        baseEffects[elem] = 'editable';
      }
    }

    if (Array.isArray(issue.readonlyInterfaces)) {
      for (const elem of issue.readonlyInterfaces) {
        baseEffects[elem] = 'readonly';
      }
    }

    if (Array.isArray(issue.editableInterfaces)) {
      for (const elem of issue.editableInterfaces) {
        baseEffects[elem] = 'editable';
      }
    }
    //TODO if rules on interfaces are added 
    //const rules = settings.rules.filter(rule => rule.onState.includes(issue.stateId) || rule.onState.includes('*'));
    //DO STUFF

    const performer = this.getUserIdentities(user, issue);
    /// Applico le restrizioni per utenti non assegnatari o reporter
    //const notAllowedUser = !(issue.reporterId === user.id || issue.assigneeId === user.id);
    const notAllowedUser = user.globalRoleId === SystemRole.Service || !performer.some(p => ['Assignee', 'Reporter', 'Admin'].includes(p));
    if (notAllowedUser) {
      for (const elem of getInterfaceNameIterable()) {
        if (result[elem] === 'editable')
          result[elem] = 'readonly'
      }
    }

    return result;
  }

  static getAutomationDeltaForTransfer(settings: WorkFlowSettings, issue: IIssue, newStateId: ID, projectScope: IProjectScope): {
    deltaIssue: Partial<IIssue>,
    deltaInterfaces: DeltaInterface[]
  } {
    let deltaIssue: Partial<IIssue> = {};
    let deltaInterfaces: DeltaInterface[] = []

    const automationForState = WorkflowDog.getAutomationForState(settings, newStateId);
    if (automationForState) {
      const deltaParams: DeltaParameters = { automation: automationForState, issue, newStateId, projectScope };
      return WorkflowDog.getAutomationDelta(deltaParams);
    }

    deltaIssue['visibleInterfaces'] = null;
    deltaIssue['readonlyInterfaces'] = null;
    deltaIssue['hiddenInterfaces'] = null;
    deltaIssue['editableInterfaces'] = null;

    return {
      deltaIssue,
      deltaInterfaces
    }
  }

  static getAutomationDelta(params: DeltaParameters): {
    deltaIssue: Partial<IIssue>,
    deltaInterfaces: DeltaInterface[],
    warningMessages?: string[]
  } {
    const deltaIssue: Partial<IIssue> = {};
    const deltaInterfaces: DeltaInterface[] = [];
    const warningMessages: string[] = [];

    const automation = params.automation;

    if (!automation)
      return {
        deltaIssue,
        deltaInterfaces,
        warningMessages
      }

    if (automation.assignee) {
      console.log('automation assignee');
      switch (automation.assignee) {
        case '_creator':
          if (params.issue.createdBy && params.issue.assigneeId !== params.issue.createdBy) {
            deltaIssue.assigneeId = params.issue.createdBy
          }
          break;
        default:
          // const assigneeId = projectScope?.users.find(u => u.roles.includes(automation.assignee))?.id;
          // deltaIssue.assigneeId = assigneeId;
          const desiredRoleId = automation.assignee;
          const allUsersInDesiredRole = params.projectScope?.users.filter(c => c.roles.includes(desiredRoleId));
          if (allUsersInDesiredRole && allUsersInDesiredRole.length > 0) {
            const allUsersInDesiredRoleIds = allUsersInDesiredRole.map(c => c.id);
            // se l'utente assegnatario è già nel ruolo corretto non faccio niente, altrimenti provo ad assegnarlo io al primo del gruppo
            if (!allUsersInDesiredRoleIds.includes(params.issue.assigneeId)) {
              if (params.usersWorkload) {
                console.log("Mappa workload", JSON.stringify(params.usersWorkload));
                const filteredUsersWorkload = Array.from(params.usersWorkload).filter(c => allUsersInDesiredRoleIds.includes(c[0]));
                deltaIssue.assigneeId = filteredUsersWorkload.reduce((prev, curr) => prev[1].assignee < curr[1].assignee ? prev : curr)?.[0];
              }
              else {
                deltaIssue.assigneeId = allUsersInDesiredRoleIds[0];
              }
            }
          }
          // non ho trovato nessuno con il ruolo desiderato...svuoto il campo per evitare che rimanga una assegnatario non dovuto?
          else {
            deltaIssue.assigneeId = null;
          }
      }
    }

    if (automation.assignee_preference) {
      console.log('automation assignee preference');
      const { resourceId, warningMessage } = WorkflowDog.getAssigneeResourceFromAppointments(params.appointments, automation.assignee_preference);
      if (resourceId) {
        if (params.issue.assigneeId !== resourceId) {
          deltaIssue.assigneeId = resourceId;
        }
      }
      else {
        warningMessages.push(warningMessage);
      }
    }

    if (automation.reporter) {
      console.log('automation reporter');
      switch (automation.reporter) {
        case '_creator':
          if (params.issue.createdBy && params.issue.reporterId !== params.issue.createdBy) {
            deltaIssue.reporterId = params.issue.createdBy;
          }
          break;
        default:
          // const assigneeId = projectScope?.users.find(u => u.roles.includes(automation.assignee))?.id;
          // deltaIssue.assigneeId = assigneeId;
          const desiredRoleId = automation.reporter;
          const allUsersInDesiredRole = params.projectScope?.users.filter(c => c.roles.includes(desiredRoleId));
          if (allUsersInDesiredRole && allUsersInDesiredRole.length > 0) {
            const allUsersInDesiredRoleIds = allUsersInDesiredRole.map(c => c.id);
            // se l'utente assegnatario è già nel ruolo corretto non faccio niente, altrimenti provo ad assegnarlo io al primo del gruppo
            if (!allUsersInDesiredRoleIds.includes(params.issue.reporterId)) {
              if (params.usersWorkload) {
                const filteredUsersWorkload = Array.from(params.usersWorkload).filter(c => allUsersInDesiredRoleIds.includes(c[0]));
                deltaIssue.reporterId = filteredUsersWorkload.reduce((prev, curr) => prev[1].reporter < curr[1].reporter ? prev : curr)?.[0];
              }
              else {
                deltaIssue.reporterId = allUsersInDesiredRoleIds[0];
              }
            }
          }
          // non ho trovato nessuno con il ruolo desiderato...svuoto il campo per evitare che rimanga una assegnatario non dovuto?
          else {
            deltaIssue.reporterId = null;
          }
      }
    }

    if (automation.reporter_preference) {
      console.log('automation reporter preference');
      const { resourceId, warningMessage } = WorkflowDog.getReporterResourceFromAppointments(params.appointments, automation.reporter_preference);
      if (resourceId) {
        if (params.issue.reporterId !== resourceId) {
          deltaIssue.reporterId = resourceId;
        }
      }
      else {
        warningMessages.push(warningMessage);
      }
    }

    if (automation.interfaces) {
      let readonlyInterfaces = params.issue.readonlyInterfaces ? params.issue.readonlyInterfaces.slice() : [];
      let hiddenInterfaces = params.issue.hiddenInterfaces ? params.issue.hiddenInterfaces.slice() : [];
      let visibleInterfaces = params.issue.visibleInterfaces ? params.issue.visibleInterfaces.slice() : [];
      let editableInterfaces = params.issue.editableInterfaces ? params.issue.editableInterfaces.slice() : [];
      for (const elem of automation.interfaces) {
        const interfaceId = elem.id; //interfaceName
        switch (elem.value) {
          case 'hidden':
            hiddenInterfaces.push(interfaceId);
            readonlyInterfaces = readonlyInterfaces.filter(el => el !== interfaceId);
            visibleInterfaces = visibleInterfaces.filter(el => el !== interfaceId);
            editableInterfaces = editableInterfaces.filter(el => el !== interfaceId);
            break;
          case 'readonly':
            readonlyInterfaces.push(interfaceId);
            hiddenInterfaces = hiddenInterfaces.filter(el => el !== interfaceId);
            editableInterfaces = editableInterfaces.filter(el => el !== interfaceId);
            break;
          case 'editable':
            editableInterfaces.push(interfaceId);
            readonlyInterfaces = readonlyInterfaces.filter(el => el !== interfaceId);
            hiddenInterfaces = hiddenInterfaces.filter(el => el !== interfaceId);
            break;
          case 'visible':
            hiddenInterfaces = hiddenInterfaces.filter(el => el !== interfaceId);
            visibleInterfaces.push(interfaceId);
            break;
          case 'not_valid':
            deltaInterfaces.push({
              interfaceName: interfaceId,
              delta: {
                issueId: params.issue.id,
                isValid: false,
              }
            })
        }
      }
      deltaIssue.hiddenInterfaces = [...new Set(hiddenInterfaces)];
      deltaIssue.readonlyInterfaces = [...new Set(readonlyInterfaces)];
      deltaIssue.visibleInterfaces = [...new Set(visibleInterfaces)];
      deltaIssue.editableInterfaces = [...new Set(editableInterfaces)];
    }

    return {
      deltaIssue,
      deltaInterfaces,
      warningMessages
    }
  }

  static getInterfacesToCheck(settings: WorkFlowSettings): InterfaceNameValue[] {
    if (!settings || !settings.flow || settings.flow.length === 0) {
      return [];
    }
    const interfaceIds = settings.flow.reduce((acc, elem) => {
      if (elem.interfaces?.length > 0) {
        elem.interfaces.forEach(el => {
          acc.add(el.id);
        });
      }
      return acc;
    }, new Set<InterfaceNameValue>());
    return [...interfaceIds];
  }

  static getAutomationForState(settings: WorkFlowSettings, stateId: string): Automations {
    return settings?.automations?.find(value => value.onState === stateId);
  }

  static getAssigneeResourceFromAppointments(appointments: IAppointment[], preference: string): { resourceId: ID, warningMessage?: string } {
    switch (preference) {
      case preference.match(/^#RESOURCE/)?.input:
        {
          if (appointments) {
            const appointment = appointments.find(a => a.entityName.toLowerCase() === preference.split('|')[1]?.toLowerCase())
            console.log('APPOINTMENT', appointment);
            if (appointment) {
              return { resourceId: appointment.userId }
            }
            else {
              return { resourceId: null, warningMessage: "ISSUE.CHANGE_STATE_ALERT.ASSIGNEE_NO_RESOURCE_MATCHED" }
            }
          }
          else {
            return { resourceId: null, warningMessage: "ISSUE.CHANGE_STATE_ALERT.ASSIGNEE_NO_RESOURCE_MATCHED" }
          }
        }
      case preference.match(/^#MANAGER_RESOURCE/)?.input:
        {
          if (appointments) {
            const appointment = appointments.find(a => a.entityName.toLowerCase() === preference.split('|')[1]?.toLowerCase())
            console.log('APPOINTMENT', appointment);
            if (appointment) {
              return { resourceId: appointment.managerId }
            }
            else {
              return { resourceId: null, warningMessage: "ISSUE.CHANGE_STATE_ALERT.ASSIGNEE_NO_MANAGER_RESOURCE_MATCHED" }
            }
          }
          else {
            return { resourceId: null, warningMessage: "ISSUE.CHANGE_STATE_ALERT.ASSIGNEE_NO_MANAGER_RESOURCE_MATCHED" }
          }
        }
    }
  }

  static getReporterResourceFromAppointments(appointments: IAppointment[], preference: string): { resourceId: ID, warningMessage?: string } {
    switch (preference) {
      case preference.match(/^#RESOURCE/)?.input:
        {
          if (appointments) {
            const appointment = appointments.find(a => a.entityName.toLowerCase() === preference.split('|')[1]?.toLowerCase())
            console.log('APPOINTMENT', appointment);
            if (appointment) {
              return { resourceId: appointment.userId }
            }
            else {
              return { resourceId: null, warningMessage: "ISSUE.CHANGE_STATE_ALERT.REPORTER_NO_RESOURCE_MATCHED" }
            }
          }
          else {
            return { resourceId: null, warningMessage: "ISSUE.CHANGE_STATE_ALERT.REPORTER_NO_RESOURCE_MATCHED" }
          }
        }
      case preference.match(/^#MANAGER_RESOURCE/)?.input:
        {
          if (appointments) {
            const appointment = appointments.find(a => a.entityName.toLowerCase() === preference.split('|')[1]?.toLowerCase())
            console.log('APPOINTMENT', appointment);
            if (appointment) {
              return { resourceId: appointment.managerId }
            }
            else {
              return { resourceId: null, warningMessage: "ISSUE.CHANGE_STATE_ALERT.REPORTER_NO_MANAGER_RESOURCE_MATCHED" }
            }
          }
          else {
            return { resourceId: null, warningMessage: "ISSUE.CHANGE_STATE_ALERT.REPORTER_NO_MANAGER_RESOURCE_MATCHED" }
          }
        }
    }
  }

  static canEditTask(user: IUserWithRoles, issue: IIssue) {
    const userIdentity = this.getUserIdentities(user, issue);
    if (userIdentity.includes('Admin')) {
      return true;
    }
    if (userIdentity.includes('Assignee')) {
      return true;
    }
    if (userIdentity.includes('Reporter')) {
      return true;
    }
    return false;
  }

}
