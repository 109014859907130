import { Injectable } from "@angular/core";
import { IID_OL_ESPLETATO_KO } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IIDolEspletatoKoQuery } from "./espletatoKo.query";
import { IIDolEspletatoKoStore } from "./espletatoKo.store";


@Injectable({
  providedIn: 'root'
})
export class IIDolEspletatoKoService extends CrudService<IID_OL_ESPLETATO_KO, IIDolEspletatoKoStore, IIDolEspletatoKoQuery> {
  constructor(
    protected store: IIDolEspletatoKoStore,
    protected query: IIDolEspletatoKoQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_ESPLETATO_KO,
      store,
      query,
      actionService,
      updateStrategy
    );
  }
}
