import { Injectable } from '@angular/core';
import { IIDolCondominioStore } from './iidOlCondominio.store';
import { IID_OL_CONDOMINIO } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDolCondominioQuery } from './iidOlCondominio.query'
import { CrudService } from '../crudService';
import { ProjectQuery } from '../project/project.query';
import { SocketIoService } from '@ep-om/core/services/socket-io.service';
import { LastUpdateQuery } from '../lastUpdate/lastUpdate.query';
import { LastUpdateService } from '../lastUpdate/lastUpdate.service';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDolCondominioService extends CrudService<IID_OL_CONDOMINIO, IIDolCondominioStore, IIDolCondominioQuery> {
  constructor(
    protected store: IIDolCondominioStore,
    protected query: IIDolCondominioQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_OL_CONDOMINIO,
      store,
      query,
      actionService,
      updateStrategy
    );
  }
}
