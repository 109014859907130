import { IBaseEntity, IProjectEntity, PartialUpdate } from "@common/interfaces/base";
import { ID } from "@common/interfaces/id";
import { Topics } from "@common/interfaces/topics";
import { ObjectHelpers as OH } from '@common/utils/object.helpers';
import { merge } from '@corex/deepmerge';
import { EntityState, EntityStore, QueryEntity } from "@datorama/akita";
import { diff } from "deep-object-diff";
import { ActionService } from "./action/action.service";
import { HandlerName } from "./action/handlers/handlers";
import { UpdateStoreStrategy } from './updateStoreStrategies';

const DEFAULT_CREATE_OPTIONS: ICreateOptions = {
  onlyLocal: false,
}
export interface ICreateOptions {
  onlyLocal?: boolean;
  handlerName?: HandlerName;
}
export abstract class CrudService<
  T extends IBaseEntity,
  Store extends EntityStore<EntityState<T>>,
  Query extends QueryEntity<EntityState<T>>
> {

  //protected abstract topic: Topics;

  constructor(
    protected topic: Topics,
    protected store: Store,
    protected query: Query,
    protected actionService: ActionService,
    protected updateStoreStrategy: UpdateStoreStrategy
  ) {
    this.updateStoreStrategy?.start(this.topic, this.store.constructor.name);
  }

  setLoading(isLoading: boolean) {
    this.store.setLoading(isLoading);
  }

  create(payload: T, options?: ICreateOptions) {
    options = { ...DEFAULT_CREATE_OPTIONS, ...options };
    delete payload.createdAt;
    delete payload.updatedAt;
    delete payload.lastUpdatedBy;
    this.store.add(payload);
    if (options.onlyLocal) {
      return;
    }
    this.actionService.queueAction({ topic: this.topic, type: 'create', payload, ...(options.handlerName && { responseHandlerName: options.handlerName }) });
  }

  deleteAction(id: string) {
    this.actionService.queueAction({ topic: this.topic, type: "delete", payload: { id } })
  }

  delete(id: ID) {
    this.update({ id, deletedAt: (new Date()).toISOString() } as PartialUpdate<T>);
  }

  restore(id: ID) {
    this.update({ id, deletedAt: null } as PartialUpdate<T>);
  }

  localRemoveEntity(id: ID) {
    this.store.remove(id);
  }


  localRemoveEntities(ids: ID[]) {
    if (this.topic === Topics.IID_GENERIC_SUSPENSION) {
      console.log('removing generic_suspension for', ids);
      console.log(this.query.getAll().filter(e => ids.includes(e.id)));
    }
    this.store.remove(ids);
  }

  localRemoveByIssueAndProject(issue: ID, project: ID) {
    this.store.remove(({ projectId, issueId }) => projectId === project && issueId === issue)
  }


  localRemoveByIssue(issue: ID) {
    if (this.topic === Topics.ISSUES) {
      console.log('removing issue ', issue)
      console.log(this.query.getAll().filter((e: any) => e.issueId === issue));
    }
    const entities = this.query.getAll({
      filterBy: (entity: any) => entity.issueId === issue,
    })
    this.store.remove(entities.map(e => e.id));
  }

  removeByProject(projectId: ID) {
    const entities = this.query.getAll({
      filterBy: (entity: unknown) => {
        const issueEntity = entity as IProjectEntity;
        return issueEntity.projectId === projectId;
      }
    });
    this.store.remove(entities.map(e => e.id));
  }

  update(data: PartialUpdate<T>, onlyLocal?: boolean) {
    const previous = this.query.getEntity(data.id);
    let payload = diff(previous, data);
    if (OH.hasNestedObject(data)) {
      payload = merge([payload, data]);
    }

    OH.purgeUndefined(payload);
    const resultObject = {};
    for (const key of Object.keys(payload)) {
      resultObject[key] = data[key];
    }

    if (!OH.hasProps(payload)) {
      //callback && callback();
      return;
    }
    payload = { id: data.id, ...resultObject }
    this.store.upsert(data.id, data);
    if (onlyLocal) {
      return;
    }
    this.actionService.queueAction({ topic: this.topic, type: "update", payload: payload, backup: previous })
  }

  remoteGet(queryParam: object, handlerName?: HandlerName) {
    console.log('getting appointments for users: ', queryParam, 'handler:', handlerName);
    this.actionService.queueAction({ topic: this.topic, type: 'get', payload: queryParam, ...(handlerName && { responseHandlerName: handlerName }) });
  }
}
