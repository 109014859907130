import { IProject } from '@common/interfaces/project';
import { EntityState, EntityStore, StoreConfig, EntityUIStore, ActiveState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ID as string } from '@common/interfaces/id';
import { Topics } from '@common/interfaces/topics';


export type JProjectUI = {
  id: string;
  newsBadge: string[];
  item: string;
  itemView: { [item: string]: string }
}

export interface ProjectState extends EntityState<IProject, string>, ActiveState<string> { };
export interface ProjectUIState extends EntityState<JProjectUI, string>, ActiveState<string> { };

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: Topics.PROJECTS, resettable: true })
export class ProjectStore extends EntityStore<ProjectState> {

  ui: EntityUIStore<ProjectUIState>

  constructor() {
    super();
    this.createUIStore().setInitialEntityState({ newsBadge: [] });
  }
}
