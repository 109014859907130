import { Injectable } from '@angular/core';
import { WorkflowStore } from './workflow.store';
import { WorkflowQuery } from './workflow.query';
import { BaseEntityStoreStrategy } from '../updateStoreStrategies';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IWorkflow } from '@common/interfaces/workflow';
import { CrudService } from '../crudService';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService extends CrudService<IWorkflow, WorkflowStore, WorkflowQuery> {
  constructor(
    public store: WorkflowStore,
    public query: WorkflowQuery,
    protected actionService: ActionService,
    protected updateStrategy: BaseEntityStoreStrategy) {
    super(
      Topics.WORKFLOWS,
      store,
      query,
      actionService,
      updateStrategy
    );

  }

  updateWorkflowSettings(payload: Pick<IWorkflow, 'id' & 'settings'>) {
    this.actionService.queueAction({ topic: this.topic, type: "update", payload})
  }
}
