import { Injectable } from '@angular/core';
import { IIDepTicketStore } from './iidEpTicket.store';
import { IID_EP_TICKET } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDepTicketQuery } from './iidEpTicket.query'
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
@Injectable({
  providedIn: 'root'
})
export class IIDepTicketService extends CrudService<IID_EP_TICKET, IIDepTicketStore, IIDepTicketQuery> {
  constructor(
    protected store: IIDepTicketStore,
    protected query: IIDepTicketQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_EP_TICKET,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
