import { Injectable } from '@angular/core';
import { UserStore } from './user.store';
import { IUser } from '@common/interfaces/user';
import { diff } from 'deep-object-diff';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { UserQuery } from './user.query';
import { AuthQuery } from '@ep-om/project/auth/auth.query';
import { ID } from '@common/interfaces/id';
import { CrudService } from '../crudService';
import { BaseEntityStoreStrategy } from '../updateStoreStrategies';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CrudService<IUser, UserStore, UserQuery> {

  constructor(
    protected store: UserStore,
    public query: UserQuery,
    protected authQuery: AuthQuery,
    protected actionService: ActionService,
    protected updateStrategy: BaseEntityStoreStrategy,
  ) {
    super(
      Topics.USERS,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

  setLoading(isLoading: boolean) {
    this.store.setLoading(isLoading);
  }

  // @TODO USARE projectQuery.myUser$
  getLoggedUser$(): Observable<IUser> {
    return this.authQuery.userId$.pipe(
      switchMap(id => this.query.selectEntity(id))
    );
  }

  getLoggedUser(): Promise<IUser> {
    return this.getLoggedUser$().pipe(take(1)).toPromise();
  }

  updateUser(user: Partial<IUser>) {
    const prevUser = this.query.getEntity(user.id);
    let payload = diff(prevUser, user) as Partial<IUser> & {id: string | number};
    payload = { id: user.id, ...payload, settings: { ...user.settings } }
    this.store.upsert(user.id, user);
    this.actionService.queueAction({ topic: this.topic, type: "update", payload, backup: prevUser });
  }

  updateUserNotificationSettings(user: Partial<IUser>) {
    const prevUser = this.query.getEntity(user.id);
    const payload = { id: user.id, notificationSettings: { ...user.notificationSettings } }
    this.store.upsert(user.id, user);
    this.actionService.queueAction({ topic: this.topic, type: "update", payload, backup: prevUser });
  }

  toggleFavoriteProject(projectId: string): void {
    let favoriteProjects: ID[] = [];
    const userId = this.authQuery.getValue().userId;
    const user = this.query.getEntity(userId);

    let currentFavorites = user.settings.favoriteProjects;

    if (!currentFavorites) currentFavorites = [];


    if (currentFavorites.includes(projectId)) {
      favoriteProjects = currentFavorites.filter(id => id !== projectId);
    } else {
      favoriteProjects = [...currentFavorites, projectId];
    }

    this.updateUser({ ...user, settings: { ...user.settings, favoriteProjects: favoriteProjects } });
  }

  updateAvatar(user: Partial<IUser>) {
    const prevUser = this.query.getEntity(user.id);
    let payload = { id: user.id, avatar: user.avatar };
    this.store.upsert(user.id, user);
    this.actionService.queueAction({ topic: this.topic, type: "update", payload, backup: prevUser })
  }

  removeAvatar(userId: ID) {
    const prevUser = this.query.getEntity(userId);
    let payload = { id: userId, avatar: null };
    this.store.upsert(userId, payload);
    this.actionService.queueAction({ topic: this.topic, type: "update", payload, backup: prevUser })
  }
}
