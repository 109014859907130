import { ID } from "./id";

export interface IBaseEntity {
    id: string,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string,
    createdBy?: ID,
    lastUpdatedBy?: ID,
    archivedAt?: string
}

export type Paginated<T> = {
  remaining: number,
  size: number,
  data: T[]
}

export function IsPaginated<T>(x: any): x is Paginated<T> {
  return 'remaining' in x && 'size' in x && 'data' in x;
}

export interface IProjectEntity extends IBaseEntity {
    projectId: ID
}

export interface IIssueEntity extends IProjectEntity {
    issueId: ID
}

export interface IBaseInterfaceData extends IIssueEntity {
    isValid: boolean;
    archivedAt?: string
}

export type PartialUpdate<T extends {id: ID}> = Partial<T> & Pick<T, 'id'>
//still not working - do not use it
//export type PartialCreate<T extends {id: ID}> = Omit<T, 'updatedtAt' | 'createdAt' | 'lastUpdatedBy' | 'createdBy' | 'deletedAt'>
export type Nullable<T> = T | null;

export interface IRequestCtx
{
  userId: ID,
  clientId: ID,
  ts: Date,
  companyId?:string,
}

export interface IGenericObj {[id:string]:any }

export interface IError { message:string }
