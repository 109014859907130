import { Injectable } from "@angular/core";
import { IID_GENERIC_APPOINTMENT } from "@common/interfaces/interfaceData";
import { Topics } from "@common/interfaces/topics";
import { ActionService } from "../action/action.service";
import { CrudService } from "../crudService";
import { ProjectEntityStoreStrategy } from "../updateStoreStrategies";
import { IID_Generic_AppointmentQuery } from "./genericAppointment.query";
import { IID_Generic_AppointmentStore } from "./genericAppointment.store";

@Injectable({
  providedIn: 'root'
})
export class IID_Generic_AppointmentService extends CrudService<IID_GENERIC_APPOINTMENT, IID_Generic_AppointmentStore, IID_Generic_AppointmentQuery> {

  constructor(
    protected store: IID_Generic_AppointmentStore,
    protected query: IID_Generic_AppointmentQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_GENERIC_APPOINTMENT,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

}
