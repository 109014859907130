import { Injectable } from "@angular/core";
import { IBaseEntity, IProjectEntity, IsPaginated, Paginated } from "@common/interfaces/base";
import { InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { RoleNames } from "@common/interfaces/permissions";
import { Topics } from "@common/interfaces/topics";
import { ObjectHelpers } from "@common/utils/object.helpers";
import { EntityStoreAction, runEntityStoreAction, transaction } from '@datorama/akita';
import { SocketIoService } from '@ep-om/core/services/socket-io.service';
import { catchError, concatMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthQuery } from "../auth/auth.query";
import { AuthStore } from "../auth/auth.store";
import { AppointmentService } from "./appointment/appointment.service";
import { interfaceDataStoreMap } from "./interfaceDataMapping";
import { IssueService } from "./issue/issue.service";
import { LastUpdateStatus } from "./lastUpdate/lastUpdate.model";
import { LastUpdateQuery } from './lastUpdate/lastUpdate.query';
import { LastUpdateService } from './lastUpdate/lastUpdate.service';
import { ProjectQuery } from './project/project.query';
import { ProjectService } from "./project/project.service";
import { ProjectScopeQuery } from "./projectScope/projectScope.query";
import { UserService } from "./user/user.service";
import { WorkflowQuery } from "./workflow/workflow.query";
import { WorkflowDependentQuery } from "./workflowDependentQuery";

export abstract class UpdateStoreStrategy {
  abstract start(topic: Topics, _store: string): void;

  abstract updateStore(topic: Topics, updates: { topic: Topics, body: Paginated<IBaseEntity> | IBaseEntity[] }): void;

  @transaction()
  protected persistUpdates<T extends IBaseEntity>(updates: { topic: string, data: T[] }, storeName: string): T[] {
    const toBeDeleted = updates.data.reduce((acc, d) => { if (!!d.deletedAt || !!d.archivedAt) { acc.push(d.id) } return acc; }, []);
    const toBeUpserted = updates.data.filter(d => !d.deletedAt);
    if (toBeUpserted.length > 0) {
      runEntityStoreAction(storeName, EntityStoreAction.UpsertManyEntities, upsertManyEntities => upsertManyEntities(toBeUpserted));
    }
    if (toBeDeleted.length > 0) {
      runEntityStoreAction(storeName, EntityStoreAction.RemoveEntities, removeEntities => removeEntities(toBeDeleted));
    }
    return toBeUpserted;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectEntityStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService,
    protected workflow: WorkflowDependentQuery,
  ) { super(); console.log('project entity store strategy constructor') }
  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => this.projectQuery.activeNotNull$),
      switchMap(project => this.workflow.selectInterfaceNameByWorkflow$(project.workflowId).pipe(
        map(interfaces => ({ project, interfaces }))
      )),
      filter(({ project, interfaces }) => {
        const interfaceStores = Object
          .entries(interfaceDataStoreMap)
          .reduce(
            (
              acc, [intName, store]: [InterfaceNameValue, any]
            ) => {
              acc[intName] = store.akitaConfig.storeName;
              return acc;
            }, {}
          );
        const isTopicAnInterface = Object.values(interfaceStores).some(store => store === topic);
        console.log('[UPDATE] is topic', topic, 'an interface?', isTopicAnInterface);
        // project entity that are not interface must always be in sync
        if (!isTopicAnInterface) {
          return true;
        }
        //check if topic refers to an interface of the current topic, if not it should not be synced
        const isCurrentProjectInterface = interfaces.some(intName => interfaceStores[intName] === topic);
        console.log('[UPDATE] is topic', topic, 'an allowed interface?', isCurrentProjectInterface);
        return isCurrentProjectInterface;
      }),
      tap(({ project, interfaces }) => {
        console.log('LAST UPDATE STARTING TOPIC', topic);
        this.lastUpdateService.setStoreInSync(topic, project.id);
      }),
      map(({ project, interfaces }) => project.id),
      concatMap(async projectId => {
        const getUpdates = async () => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          const key = `PRJ_${projectId}_${topic}`;
          const lastUpdateQueryParams = lastUpdates ? lastUpdates[key] : null;
          console.log('getting updates for', topic);
          if (!this.socketIoService.connected$.value) {
            throw new Error('not connected');
          }
          const result = await this.socketIoService.sendRequest<Paginated<IProjectEntity>>(topic, 'first_sync', {
            filters: {
              projectId, updatedAt: lastUpdateQueryParams?.lastUpdate, lastId: lastUpdateQueryParams?.lastId
            }
          });
          console.log('got', result);
          return result;
        }
        for (let updates = await getUpdates(); updates.body.remaining > 0; updates = await getUpdates()) {
          this.updateStore(topic, { topic, body: updates.body });
          console.log('[FIRST SYNC] - upserted -', updates.body.data.length, 'entities - ', topic, 'project', projectId);
        }
        console.log('[FIRST SYNC] - completed -', topic, 'project', projectId);

        return projectId;
      }),
      catchError((err, caught) => {
        console.error(err);
        return caught;
      }),
      tap((projectId) => this.lastUpdateService.setStoreLive(topic, projectId)),
      switchMap((projectId: string) => {
        const lastUpdates = this.lastUpdateQuery.getValue();
        const key = `PRJ_${projectId}_${topic}`;
        return this.socketIoService.listenEntity<IProjectEntity>(topic, { projectId, updatedAt: lastUpdates[key].lastUpdate, lastId: lastUpdates[key].lastId })
      }),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(
      (updates: {
        topic: Topics;
        data: IProjectEntity[] | Paginated<IProjectEntity>;
      }) => this.updateStore(topic, { topic: updates.topic, body: updates.data })
    )
  }

  updateStore(topic: Topics, updates: { topic: Topics, body: Paginated<IProjectEntity> | IProjectEntity[] }) {
    console.log('update store with updates', updates);
    let data: IProjectEntity[];
    let remaining: number;
    if (IsPaginated(updates.body)) {
      data = updates.body.data;
      remaining = updates.body.remaining;
    } else {
      data = updates.body;
    }
    if (data.length === 0) {
      return;
    }
    this.persistUpdates({ topic: updates.topic, data: data }, topic);
    const lastItem = data.at(-1);
    this.lastUpdateService.setChildLastUpdate(lastItem.projectId, updates.topic, {
      lastId: lastItem.id,
      lastUpdate: lastItem.updatedAt,
      ...(remaining && { remaining }),
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class BaseEntityStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService
  ) { super() }

  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      tap(() => this.lastUpdateService.setStoreInSync(topic)),
      concatMap(async () => {
        const getUpdates = async () => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          const key = topic;
          const lastUpdateQueryParams = lastUpdates ? lastUpdates[key] : null;
          if (!this.socketIoService.connected$.value) {
            throw new Error('not connected');
          }
          return await this.socketIoService.sendRequest<Paginated<IBaseEntity>>(topic, 'first_sync', {
            filters: {
              updatedAt: lastUpdateQueryParams?.lastUpdate, lastId: lastUpdateQueryParams?.lastId
            }
          });
        }
        for (let updates = await getUpdates(); updates.body.remaining > 0; updates = await getUpdates()) {
          this.updateStore(topic, { topic, body: updates.body });
          console.log('[FIRST SYNC] - upserted -', updates.body.data.length, 'entities - ', topic);
        }
        console.log('[FIRST SYNC] - completed -', topic);
      }),
      catchError((err, caught) => {
        console.error(err);
        return caught;
      }),
      tap(() => this.lastUpdateService.setStoreLive(topic)),
      switchMap(() => {
        const lastUpdates = this.lastUpdateQuery.getValue();
        const key = topic;
        return this.socketIoService.listenEntity<IBaseEntity>(topic, { updatedAt: lastUpdates[key].lastUpdate, lastId: lastUpdates[key].lastId })
      }),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(
      updates => this.updateStore(topic, { topic: updates.topic, body: updates.data })
    )
  }
  updateStore(topic: Topics, updates: { topic: Topics, body: Paginated<IBaseEntity> | IBaseEntity[] }) {
    let data: IBaseEntity[];
    let remaining: number;
    let status: LastUpdateStatus;
    if (IsPaginated(updates.body)) {
      data = updates.body.data;
      remaining = updates.body.remaining;
    } else {
      data = updates.body;
    }
    if (data.length === 0) {
      return;
    }
    this.persistUpdates({ topic: updates.topic, data: data }, topic);
    const lastItem = data.at(-1);
    this.lastUpdateService.setLastUpdate(topic, {
      lastId: lastItem.id,
      lastUpdate: lastItem.updatedAt,
      ...(remaining && { remaining }),
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectScopeStoreStrategy extends BaseEntityStoreStrategy {
  constructor(
    protected socketIoService: SocketIoService,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected projectService: ProjectService,
    protected issueService: IssueService,
    protected authStore: AuthStore,
    protected authQuery: AuthQuery,
    protected userService: UserService,
    protected projectScopeQuery: ProjectScopeQuery,
    protected wfQuery: WorkflowQuery,
    protected projectQuery: ProjectQuery,
    protected appointmentService: AppointmentService
  ) {
    super(
      lastUpdateQuery,
      lastUpdateService,
      socketIoService
    );
  }

  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      tap(() => this.lastUpdateService.setStoreInSync(topic)),
      concatMap(async () => {
        const getUpdates = async () => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          const key = topic;
          const lastUpdateQueryParams = lastUpdates ? lastUpdates[key] : null;
          if (!this.socketIoService.connected$.value) {
            throw new Error('not connected');
          }
          return await this.socketIoService.sendRequest<Paginated<IBaseEntity>>(topic, 'first_sync', {
            filters: {
              updatedAt: lastUpdateQueryParams?.lastUpdate, lastId: lastUpdateQueryParams?.lastId
            }
          });
        }

        for (let updates = await getUpdates(); updates.body.remaining > 0; updates = await getUpdates()) {
          this.updateStore(topic, { topic, body: updates.body });
          console.log('[FIRST SYNC] - upserted -', updates.body.data.length, 'entities - ', topic, 'project');
        }
        console.log('[FIRST SYNC] - completed -', topic, 'project');
        const newProjectRoles = this.projectScopeQuery.getAll().reduce((acc, projectScope) => {
          const currentUserRole = projectScope?.users?.find(user => user.id === this.authQuery.getLoggedUserId());
          if (!currentUserRole) {
            return acc;
          }
          acc[projectScope.projectId] = currentUserRole.roles;
          return acc;
        }, {});

        console.log('[PROJECT_SCOPE] new ProjectRoles', newProjectRoles);
        this.authStore.update(state => ({
          ...state,
          projectRole: newProjectRoles
        }));
      }),
      catchError((err, caught) => {
        console.error(err);
        return caught;
      }),
      tap(() => this.lastUpdateService.setStoreLive(topic)),
      switchMap(() => {
        const lastUpdates = this.lastUpdateQuery.getValue();
        const key = topic;
        return this.socketIoService.listenEntity<IBaseEntity>(topic, { updatedAt: lastUpdates[key].lastUpdate, lastId: lastUpdates[key].lastId })
      }),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(
      updates => {
        const visibilityManager = new VisibilityManager(this.wfQuery, this.projectScopeQuery, this.authQuery, this.projectQuery, this.appointmentService, this.projectService)
        visibilityManager
          .calculateStartingRoles()
          .calculateStartingResourcePerRoles();
        this.updateStore(topic, { topic: updates.topic, body: updates.data });
        const newProjectRoles = this.projectScopeQuery.getAll().reduce((acc, projectScope) => {
          const currentUserRole = projectScope?.users?.find(user => user.id === this.authQuery.getLoggedUserId());
          if (!currentUserRole) {
            return acc;
          }
          acc[projectScope.projectId] = currentUserRole.roles;
          return acc;
        }, {});

        console.log('[PROJECT_SCOPE] new ProjectRoles', newProjectRoles);
        this.authStore.update(state => ({
          ...state,
          projectRole: newProjectRoles
        }));

        visibilityManager
          .calculateCurrentRoles()
          .calculateCurrentResourcePerRoles()
          .cleanUpAppointmentByUsers()
          .retrieveMissingAppointmentByUsers()
          .manageLimitedVisibility();
      }
    )
  }
}

@Injectable({
  providedIn: 'root',
})
export class DummyStoreStrategy extends UpdateStoreStrategy {
  start() { }
  updateStore() { }
}


class VisibilityManager {
  loggedUserId: string;
  prevRoles: { [key: string]: RoleNames[] };
  prevResourceRoles: RoleNames[];
  prevUserPerResourceRole: string[];
  /**
   * key are project id
   */
  currentRoles: { [key: string]: RoleNames[] };
  currentResourceRoles: RoleNames[];
  currentUserPerResourceRole: string[];


  constructor(
    private wfQuery: WorkflowQuery,
    private projectScopeQuery: ProjectScopeQuery,
    private authQuery: AuthQuery,
    private projectQuery: ProjectQuery,
    private appointmentService?: AppointmentService,
    private projectService?: ProjectService,
  ) {

  }

  calculateStartingRoles() {
    this.loggedUserId = this.authQuery.getLoggedUserId();
    this.prevRoles = this.authQuery.getProjectRoles();
    console.log(['CURRENT ROLES', JSON.stringify(this.prevRoles)]);
    this.prevResourceRoles = this._getResourceRoles(this.prevRoles);
    return this;
  }

  calculateStartingResourcePerRoles() {
    this.prevUserPerResourceRole = this._getUserPerResourceRole(this.prevResourceRoles)
    return this;
  }

  calculateCurrentRoles() {
    this.currentRoles = this.authQuery.getProjectRoles();
    console.log(['CURRENT ROLES', JSON.stringify(this.currentRoles)]);
    this.currentResourceRoles = this._getResourceRoles(this.currentRoles);
    return this;
  }

  calculateCurrentResourcePerRoles() {
    this.currentUserPerResourceRole = this._getUserPerResourceRole(this.currentResourceRoles);
    return this;
  }

  cleanUpAppointmentByUsers() {
    console.log('[CLEAN UP APPOINTMENTS BY USERS]', this.prevUserPerResourceRole, this.currentUserPerResourceRole);
    const personsToRemove = this.prevUserPerResourceRole.filter(user => !this.currentUserPerResourceRole.includes(user));
    console.log('[CLEAN UP APPOINTMENTS BY USERS]', personsToRemove);
    this.appointmentService.localRemoveByUserId(personsToRemove);
    return this;
  }

  retrieveMissingAppointmentByUsers() {
    const personsToAdd = this.currentUserPerResourceRole.filter(user => !this.prevUserPerResourceRole.includes(user));
    if (personsToAdd.length > 0) {
      this.appointmentService.remoteGet({ users: personsToAdd }, 'AppointmentRemoteGet');
    }
    return this;
  }

  manageLimitedVisibility() {
    const changes = this._calculateRoleModifications();
    console.log('role changes:', changes);

    for (const [project, { addedRoles, removedRoles }] of Object.entries(changes)) {
      if (
        addedRoles?.some(role => ['CompanyLimited', 'Limited'].includes(role))
        || removedRoles?.some(role => ['CompanyLimited', 'Limited'].includes(role))
      ) {
        this.projectService.reloadProject(project);
      }
    }
  }

  private _calculateRoleModifications(): { [project: string]: { addedRoles: RoleNames[], removedRoles: RoleNames[] } } {
    const calculate = (setRoles1: { [project: string]: RoleNames[] }, setRoles2: { [project: string]: RoleNames[] }): { [key: string]: Set<RoleNames> } => {
      let result: { [key: string]: Set<RoleNames> } = {}
      for (const [project, roles] of Object.entries(setRoles1)) {
        if (!setRoles2[project]) {
          roles.forEach(role => result = {
            ...result,
            [`${project}`]: result[project]?.add(role) || new Set([role]),
          });
          continue;
        }
        for (const currentRole of roles) {
          if (!setRoles2[project].some(role => role === currentRole)) {
            result = {
              ...result,
              [`${project}`]: result[project]?.add(currentRole) || new Set([currentRole]),
            }
          }
        }
      }
      return result;
    }
    let addedRoles = calculate(this.currentRoles || {}, this.prevRoles || {});
    let removedRoles = calculate(this.prevRoles || {}, this.currentRoles || {});
    let result = {}
    Object.entries(addedRoles).forEach(([project, roles]) => result[project] = { ...result[project], addedRoles: [...roles] });
    Object.entries(removedRoles).forEach(([project, roles]) => result[project] = { ...result[project], removedRoles: [...roles] });
    return result;
  }

  private _getUserPerResourceRole(roles: RoleNames[]) {
    if (!roles || roles.length === 0) {
      return [];
    }
    return [...this.projectScopeQuery.getAll().reduce((acc, curr) => {
      if (!curr.users || curr.users.length === 0) {
        return acc;
      }
      for (const user of curr.users) {
        for (const role of user.roles) {
          if (!roles.includes(role)) {
            continue;
          }
          acc.add(user.id);
        }
      }
      return acc
    }, new Set<string>())];
  }

  private _getResourceRoles(roles: { [key: string]: RoleNames[] }) {
    if (ObjectHelpers.hasOnlyEmptyValues(roles)) {
      return [];
    }
    return [...new Set<RoleNames>(this.projectQuery.getAll().reduce((acc: RoleNames[], project) => {
      if (!this.wfQuery.getEntity(project.workflowId)?.settings?.resourceManagement?.enabled || !this.wfQuery.getEntity(project.workflowId)?.settings?.resourceManagement?.rules || this.wfQuery.getEntity(project.workflowId)?.settings?.resourceManagement?.rules.length === 0) {
        return acc;
      }
      for (const rule of this.wfQuery.getEntity(project.workflowId)?.settings?.resourceManagement?.rules || []) {
        if (rule.managerRoles.some(role => roles[project.id]?.includes(role))) {
          acc.push(...rule.resourceRoles);
        }
      };
      return acc;
    }, []))];
  }
}
