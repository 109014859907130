import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { ActionState, ActionStore } from './action.store';

@Injectable({
  providedIn: 'root'
})
export class ActionQuery extends Query<ActionState> {
  inTransfer$ = this.select('inTransfer');
  queue$ = this.select('queue');
  buffer$ = this.select('buffer');
  /*
    all$ = this.selectAll();
    active$ = this.selectActive();
    activeId$ = this.selectActiveId(); */

  constructor(protected store: ActionStore) {
    super(store);
  }
}
