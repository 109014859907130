<div class="text-xs text-red-600 leading-tight pt-1" *ngIf="control.value && control.errors">
  <ng-container *ngIf="control.errors.maxlength">Il campo non può essere più lungo di {{control.errors.maxlength.requiredLength}} caratteri.</ng-container>
  <ng-container *ngIf="control.errors.minlength">Il campo deve essere almeno {{control.errors.minlength.requiredLength}} caratteri.</ng-container>
  <ng-container *ngIf="control.errors.email">L'email non è valida.</ng-container>
  <ng-container *ngIf="control.errors.pattern">
    <ng-container *ngIf="control.errors.pattern.requiredPattern === '^[0-9]*$'">Solo caratteri numerici ammessi.</ng-container>
  </ng-container>
  <ng-container *ngIf="control.errors.codiceOrdineAlreadyExist">Il codice ordine esiste già.</ng-container>
  <ng-container *ngIf="control.errors.initialStateNotValid">Stato iniziale selezionato non valido.</ng-container>
  <ng-container *ngIf="control.errors.finalStateNotValid">Stato finale selezionato non valido.</ng-container>
  <ng-container *ngIf="control.errors.invalidDate">Data non valida</ng-container>
  <ng-container *ngIf="control.errors.invalidDateRange">Range di date non valido</ng-container>
  <ng-container *ngIf="control.errors.max">Massimo: {{control.errors.max.max}}</ng-container>
  <ng-container *ngIf="control.errors.min">Minimo: {{control.errors.min.min}}</ng-container>
</div>
<div class="text-xs text-red-600 leading-tight pt-1" *ngIf="(control.touched || control.dirty) && control.errors;">
  <ng-container *ngIf="control.errors.required">Il campo è obbligatorio.</ng-container>
</div>
