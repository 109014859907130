import { Injectable } from '@angular/core';
import { IUser, IUserWithRoles } from '@common/interfaces/user';
import { Query, toBoolean } from '@datorama/akita';
import { sha1 } from 'object-hash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AuthState, AuthStore, clientId } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  authState$ = this.select();
  userId$ = this.select('userId');
  accessToken$ = this.select('accessToken');
  refreshToken$ = this.select('refreshToken');
  roles$ = this.select('projectRole');
  loggedIn$ = this.select('accessToken').pipe(map(at=>!!at));
  user$ = this.select('user');
  projectRole$ = this.select('projectRole');

  constructor(protected store: AuthStore) {
    super(store);
  }

  getClientId(): string {
    return clientId();
  }

  isLoggedIn() {
    return toBoolean(this.getValue().accessToken);
  }

  getLoggedUserId(): string {
    return this.getValue().userId;
  }

  getLoggedUser(): IUser {
    return this.getValue().user;
  }

  getAccessToken(): string {
    return this.getValue().accessToken;
  }

  getProjectRoles() {
    return this.getValue().projectRole;
  }

  selectProjectRole$() {
    return this.select('projectRole').pipe(filter(projectRole => !!projectRole));
  }

  selectUserAndRolesByProjectId$(projectId: string): Observable<IUserWithRoles> {
    return this.select(['user', 'projectRole']).pipe(
      filter(val => !!val.user),
      map(val => ({
        ...val.user,
        roles: val.projectRole ? val.projectRole[projectId] || [] : []
      })));
  }

  selectProjectRoleByProjectId$(projectId: string) {
    return this.select('projectRole').pipe(
      map(roles => roles[projectId]),
      distinctUntilChanged((prev, curr) => sha1(prev) === sha1(curr))
    )
  }
}
