import { Injectable } from '@angular/core';
import { IID_ASSURANCE_TICKET } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDassuranceTicketStore } from './iidAssuranceTicket.store';
import { IIDassuranceTicketQuery } from './iidAssuranceTicket.query';
import { WsACKCallback } from '@common/api/ack';

@Injectable({
  providedIn: 'root'
})
export class IIDassuranceTicketService extends CrudService<IID_ASSURANCE_TICKET, IIDassuranceTicketStore, IIDassuranceTicketQuery> {
  constructor(
    protected store: IIDassuranceTicketStore,
    protected query: IIDassuranceTicketQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy) {
    super(
      Topics.IID_ASSURANCE_TICKET,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

  createTaskOnGuastiNID(sourceIssueId: string, projectDestinationId: string, issueTypeDestinationId: string, callback?: WsACKCallback<void>) {
    this.actionService.sendMessage(Topics.IID_ASSURANCE_TICKET, 'createTaskOnGuastiNID', { sourceIssueId, projectDestinationId, issueTypeDestinationId }, callback);
  }
}
