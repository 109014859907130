export enum Topics {
  DASHBOARD = "Dashboard",
  SYSMESSAGES = 'Sysmessages',
  ATTACHMENT = 'Attachment',
  ISSUES = 'Issues',
  HISTORY = 'History',
  //PERMISSION = 'Permission',
  ISSUE_TYPES = 'IssueTypes',
  PROJECTS = 'Projects',
  PROJECT_CATEGORIES = 'ProjectCategories',
  COMPANIES = 'Companies',
  PROJECT_SCOPES = 'ProjectScopes',
  ISSUE_COMMENTS = 'IssueComments',
  ENTITY_INTERACTIONS = 'EntityInteractions',
  USERS = "Users",
  WORKFLOWS = "Workflows",
  TAGS = "Tags",
  MILESTONES = "Milestones",
  APPOINTMENT = "Appointment",
  ISSUE_STATES = "IssueStates",
  ISSUE_STATE_WORKFLOWS = "IssueStateWorkflows",
  ISSUE_TYPE_INTERFACES = "IssueTypeInterfaces",
  ISSUE_TYPE_WORKFLOWS = "IssueTypeWorkflows",
  IID_OL_CLIENTE = "IID_OL_Cliente",
  IID_OL_CONTRATTO = "IID_OL_Contratto",
  IID_OL_RIFERIMENTI = "IID_OL_Riferimenti",
  IID_OL_RIFERIMENTI_UI = "IID_OL_RiferimentiUi",
  IID_OL_CONDOMINIO = "IID_OL_Condominio",
  IID_OL_OLO_REQUEST = "IID_OL_OloRequest",
  IID_OL_ACTIVATION_REQUEST = "IID_OL_ActivationRequest",
  IID_EP_ASSETS = "IID_EP_Assets",
  IID_EP_RICHIESTA_PERMESSO = "IID_EP_Richiesta_Permesso",
  IID_EP_RISPOSTA = "IID_EP_Risposta",
  IID_OL_CHECKLIST = "IID_OL_Checklist",
  IID_OL_SERVIZIO_AGGIUNTIVO = "IID_OL_ServizioAggiuntivo",
  IID_OL_APPARATO = "IID_OL_Apparato",
  IID_OL_APPUNTAMENTO = "IID_OL_Appuntamento",
  IID_OL_ACQUISITO_KO = "IID_OL_AcquisitoKo",
  IID_OL_ACCETTATO_KO = "IID_OL_AccettatoKo",
  IID_OL_ESPLETATO_KO = "IID_OL_EspletatoKo",
  IID_OL_ESPLETATO_OK = "IID_OL_EspletatoOk",
  IID_OL_ESPLETATO_OK_OF = "IID_OL_EspletatoOkOF",
  IID_OL_ANNULLATO = "IID_OL_Annullato",
  IID_OL_COMMUNICATION = "IID_OL_Communication",
  GLOBAL_BATCH_ACTIVITIES = "GLOBAL_BATCH_ACTIVITIES",
  PROJECT_BATCH_ACTIVITIES = "PROJECT_BATCH_ACTIVITIES",
  USER_BATCH_ACTIVITIES = "USER_BATCH_ACTIVITIES",
  IID_OL_SOSPENSIONE = "IID_OL_Sospensione",
  IID_EP_TICKET = "IID_EP_Ticket",
  IID_OL_SOLLECITO = "IID_OL_Sollecito",
  IID_OL_RISPOSTA_SOLLECITO = "IID_OL_RispostaSollecito",
  IID_OL_SUPPORTO = "IID_OL_Supporto",
  IID_OL_RISOLUZIONE = "IID_OL_Risoluzione",
  IID_OL_RISORSA = "IID_OL_Risorsa",
  IID_GENERIC_SUSPENSION = "IID_Generic_Suspension",
  IID_META_INTERFACE = "IID_META_INTERFACE",
  IID_EP_GISFO_SUPPORT = "IID_EP_GisfoSupport",
  IID_GR_RESOURCES = "IID_GR_Resources",
  IID_GR_TASKS = "IID_GR_Tasks",
  IID_OLO_DEACTIVATION = "IID_OLO_Deactivation",
  IID_OLO_DEACTIVATION_VALIDATION = "IID_OLO_Deactivation_Validation",
  IID_OLO_DEACTIVATION_PHYSIC = "IID_OLO_Deactivation_Physic",
  IID_GENERIC_KO = "IID_Generic_Ko",
  IID_MAIL_GTW_SOURCE = "IID_Mail_Gtw_Source",
  IID_ASSURANCE_TICKET = "IID_Assurance_Ticket",
  IID_GENERIC_OK = "IID_Generic_Ok",
  RESOURCES = "Resources",
  RESOURCE_ITEMS = "Resource_Items",
  KEYVALUES = "Keyvalues",
  IID_NETWORK_ITEMS = "IID_Network_Items",
  IID_FAULT_MANAGEMENT = "IID_Fault_Management",
  IID_GENERIC_APPOINTMENT = "IID_Generic_Appointment",
  IID_OL_OLO_REQUEST_OF = "IID_OL_OloRequest_OF",
  IID_OL_RISORSA_PNI = "IID_OL_Risorsa_PNI",
  IID_ISSUE_LINK = "IID_IssueLink",
  IID_ISSUE_LINKED = "IID_IssueLinked"
}

export enum IssueTopics {
  IID_OL_CLIENTE = "IID_OL_Cliente",
  IID_OL_CONTRATTO = "IID_OL_Contratto",
  IID_OL_RIFERIMENTI = "IID_OL_Riferimenti",
  IID_OL_RIFERIMENTI_UI = "IID_OL_RiferimentiUi",
  IID_OL_CONDOMINIO = "IID_OL_Condominio",
  IID_OL_CHECKLIST = "IID_OL_Checklist",
  IID_OL_OLO_REQUEST = "IID_OL_OloRequest",
  IID_OL_ACTIVATION_REQUEST = "IID_OL_ActivationRequest",
  IID_EP_ASSETS = "IID_EP_Assets",
  IID_OL_ACQUISITO_KO = "IID_OL_AcquisitoKo",
  IID_OL_ACCETTATO_KO = "IID_OL_AccettatoKo",
  IID_OL_ESPLETATO_OK = "IID_OL_EspletatoOk",
  IID_OL_ESPLETATO_OK_OF = "IID_OL_EspletatoOkOF",
  IID_OL_ESPLETATO_KO = "IID_OL_EspletatoKo",
  IID_OL_ANNULLATO = "IID_OL_Annullato",
  IID_EP_RICHIESTA_PERMESSO = "IID_EP_Richiesta_Permesso",
  IID_EP_RISPOSTA = "IID_EP_Risposta",
  IID_OL_SERVIZIO_AGGIUNTIVO = "IID_OL_ServizioAggiuntivo",
  IID_OL_APPARATO = "IID_OL_Apparato",
  IID_OL_APPUNTAMENTO = "IID_OL_Appuntamento",
  IID_OL_COMMUNICATION = "IID_OL_Communication",
  IID_OL_SOSPENSIONE = "IID_OL_Sospensione",
  ISSUES = 'Issues',
  ATTACHMENT = 'Attachment',
  ISSUE_COMMENTS = 'IssueComments',
  IID_OL_SOLLECITO = "IID_OL_Sollecito",
  IID_OL_RISPOSTA_SOLLECITO = "IID_OL_RispostaSollecito",
  IID_OL_SUPPORTO = "IID_OL_Supporto",
  IID_OL_RISOLUZIONE = "IID_OL_Risoluzione",
  IID_OL_RISORSA = "IID_OL_Risorsa",
  IID_GENERIC_SUSPENSION = "IID_Generic_Suspension",
  IID_EP_GISFO_SUPPORT = "IID_EP_GisfoSupport",
  IID_GR_RESOURCES = "IID_GR_Resources",
  IID_GR_TASKS = "IID_GR_Tasks",
  IID_OLO_DEACTIVATION = "IID_OLO_Deactivation",
  IID_OLO_DEACTIVATION_VALIDATION = "IID_OLO_Deactivation_Validation",
  IID_OLO_DEACTIVATION_PHYSIC = "IID_OLO_Deactivation_Physic",
  IID_GENERIC_KO = "IID_Generic_Ko",
  IID_MAIL_GTW_SOURCE = "mail_gtw_source",
  IID_ASSURANCE_TICKET = "IID_Assurance_Ticket",
  IID_GENERIC_OK = "IID_Generic_Ok",
  IID_NETWORK_ITEMS = "IID_Network_Items",
  IID_FAULT_MANAGEMENT = "IID_Fault_Management",
  IID_OL_OLO_REQUEST_OF = "IID_OL_OloRequest_OF",
  IID_OL_RISORSA_PNI = "IID_OL_Risorsa_PNI",
  IID_ISSUE_LINK = "IID_Issue_Link",
  IID_ISSUE_LINKED = "IID_Issue_Linked"
}

export type FilterType = {
  projectId?: string,
  updatedAt?: string,
  limited?: boolean,
  userId?: string,
  lastId?: string,
  companyId?: string,
}
