import { Injectable } from '@angular/core';
import { IIDolRiferimentiStore } from './iidOlRiferimenti.store';
import { IID_OL_RIFERIMENTI } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { IIDolRiferimentiQuery } from './iidOlRiferimenti.query'
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { WsACKCallback } from '@common/api/ack';
import { IBuildingInfo } from '@common/interfaces/gisfo';

const TOPIC = Topics.IID_OL_RIFERIMENTI
@Injectable({
  providedIn: 'root'
})
export class IIDolRiferimentiService extends CrudService<IID_OL_RIFERIMENTI, IIDolRiferimentiStore, IIDolRiferimentiQuery> {
  constructor(
    protected store: IIDolRiferimentiStore,
    protected query: IIDolRiferimentiQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy,
  ) {
    super(
      TOPIC,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

  refresh(id: string, callback?: WsACKCallback<IID_OL_RIFERIMENTI>) {
    this.actionService.sendMessage(TOPIC, 'refresh', { id }, callback)
  }

  selectBuilding(payload: { id: string, building: IBuildingInfo }, callback?: WsACKCallback) {
    this.actionService.sendMessage(TOPIC, 'selectBuilding', { payload }, callback);
  }

}
