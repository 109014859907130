import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, UrlSegment, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { selectPersistStateInit } from '@datorama/akita';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate, CanLoad {

  constructor(public authService: AuthService, public router: Router) {}
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await selectPersistStateInit().pipe(take(1)).toPromise();
    if (!this.authService.isLoggedIn()) {
      this.authService.setRedirectUlr(state.url);
      console.log('[AUTH GUARD] - argire');
      await this.router.navigate(['login']);
      return false;
    }
    console.log('[AUTH GUARD] - ire');
    return true;
  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    await selectPersistStateInit().pipe(take(1)).toPromise();
    if (!this.authService.isLoggedIn()) {
      this.authService.setRedirectUlr(segments.map(s => s.path).join('/'));
      await this.router.navigate(['login']);
      return false;
    }
    return true;
  }
 
}
